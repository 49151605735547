// @ts-nocheck
import Vue from 'vue'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

import {
  getOSMLayer,
  getTargetLayer,
  getRadarLayer,
  getRainpath,
  getLeafletPopup,
  getWindArrow,
  getAirParifLayer
} from './helper'
import { WeatherStationData } from '@/store/weatherstation/definitions'
import preferences from '@/services/preferences'

import Button from '@/components/ui/Button/Button.vue'

const COLOR_GOLD = 'var(--color-gold)'
const MAP_TARGET_CIRCLE_INTERVAL = 2500
const MAP_TARGET_CIRCLE_ITERATION = 42

export default Vue.extend({
  components: {
    'ui-button': Button
  },
  props: {
    id: {
      type: String,
      default: 'id1'
    },
    event: {
      type: Object,
      required: true
    },
    bbox: Object,
    times: {
      type: Array,
      default: () => ([])
    },
    timesIndex: Number,
    numberOfForwardLayers: Number,
    /** @type {ImageStrategy} */
    imageResolution: String,
    isLayerOsmDisplayed: {
      type: Boolean,
      default: true
    },
    isLayerRainpathDisplayed: Boolean,
    isLayerWeatherStationsDisplayed: Boolean,
    isLayerWindArrowsDisplayed: {
      type: Boolean,
      required: true
    },
    areStationsDisplayed: Boolean,
    dataLayer: {
      type: String,
      default: 'radar'
    },
    /** @type {ForecastDataRain} */
    rain: Object,
    /** @type {WeatherStationData[]} */
    stationsData: {
      type: Array,
      default: () => ([])
    },
    currentTime: {
      type: Object,
      default: () => ({})
    },
    // this prop help us to center the map
    // each time we have to
    // nowcasting component will bind centerMap to view
    // so when user change to zoom, radar, forecast
    // the map will 'fitCenter'
    centerMap: {
      type: String,
      default: null
    },
    centerPreference: {
      type: Object,
      default: null
    },
    osmOpacity: {
      type: Number,
      required: true
    },
    radarOpacity: {
      type: Number,
      required: true
    },
    airParifOpacity: {
      type: Number,
      required: false
    },
    displayWarningMessage: {
      type: Boolean,
      default: false
    },
    displayInfoMessage: {
      type: Boolean,
      default: false
    },
    displayWheatherStationDialogButton: {
      type: Boolean,
      default: false
    },
    warningMessage: {
      type: String
    },
    radarPeriods: {
      type: Array,
      default: () => ([])
    },
    mapBackground: {
      type: String,
      default: 'light'
    },
    mapDark: {
      type: Boolean,
      default: false
    },
    displayZoomControl: {
      type: Boolean,
      default: false
    },
    airParifLayerAllowed: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      colors: [
        { mean: '#DD66D8', gust: '#F1C4E6' },
        { mean: '#55C116', gust: '#B8F3A3' },
        { mean: '#00B3FF', gust: '#77e7fd' },
        { mean: '#FFA933', gust: '#FFC576' }
      ],
      arrowOptions: {
        className: 'arrow',
        pane: 'windarrows',
        weight: 6
      }
    }
  },
  computed: {
    isIOS () {
      return L.Browser.mobile === true && L.Browser.safari === true
    },
    center () {
      return [
        this.event.location.coordinates[1],
        this.event.location.coordinates[0]
      ]
    },
    mapId () {
      return 'map' + this.id
    }
  },
  mounted () {
    const mapZoomPrefKey = 'map_' + this.id

    this.layers = {
      osm: L.layerGroup(),
      weatherstation: L.layerGroup(),
      windarrows: L.layerGroup(),
      rainpath: L.layerGroup(),
      markerRainpathLayerFull: L.layerGroup(),
      markerRainpathLayerMid: L.layerGroup(),
      markerRainpathLayerSmall: L.layerGroup(),
      time: [],
      airquality: []
    }
    this.zoomFull = 12
    this.zoomSmall = 10

    L.Map.include({
      openPopup: function (popup, latlng, options) {
        // (Popup) or (String || HTMLElement, LatLng[, Object])
        if (!(popup instanceof L.Popup)) {
          const content = popup
          popup = new L.Popup(options).setContent(content)
        }

        if (latlng) {
          popup.setLatLng(latlng)
        }

        if (this.hasLayer(popup)) {
          return this
        }

        // this.closePopup();
        this._popup = popup
        return this.addLayer(popup)
      }
    })
    const mapCenter = this.centerPreference
      ? [
        this.centerPreference.lat,
        this.centerPreference.lng
      ]
      : this.center

    this.map = L.map(this.mapId, {
      attributionControl: true,
      zoomControl: false,
      zoom: preferences.zooms[mapZoomPrefKey] || 10,
      minZoom: 8, // 20km
      maxZoom: 16, // 300m
      center: mapCenter,
      closePopupOnClick: false,
      dragging: !L.Browser.mobile,
      tap: !L.Browser.mobile
    })
    this.map.createPane('target')
    this.map.createPane('rainpath')
    this.map.createPane('windarrows')

    const eventsToEmit = ['movestart', 'moveend']
    eventsToEmit.forEach(eventType => {
      this.map.on(eventType, () => {
        switch (eventType) {
          case 'movestart':
            this.$emit(eventType)
            break
          case 'moveend':
            /**
             * We don't emit a param as the actual center of the map
             * if it's the default center of the map
             */
            // eslint-disable-next-line no-case-declarations
            const actualCenter = this.map.getCenter()
            // eslint-disable-next-line no-case-declarations
            const param = (actualCenter.lat === this.center.lat && actualCenter.lng === this.center.lng) ? null : actualCenter

            this.$emit(eventType, param)
            break
        }
      })
    })

    this.map.on('zoomend', () => {
      this.adaptLayerTarget()
      this.adaptLayerRainpath()
      preferences.updateZooms(mapZoomPrefKey, this.map.getZoom())
    })

    this.addOSMLayer()

    L.geoJSON(this.event.geojson, {
      color: COLOR_GOLD,
      interactive: false,
      fill: false
    } as L.GeoJSONOptions).addTo(this.map)

    this.drawLayerTarget()
    if (this.rain) {
      this.drawRainpath(this.rain.speed, this.rain.direction)
    }
    this.drawLayerWeatherData()
    this.adaptLayerTarget()
    this.adaptLayerRainpath()
    if (this.times !== null && this.timesIndex !== null) {
      this.addDataLayer(this.times, this.timesIndex)
    }
    this.isLayerRainpathDisplayed === true && this.toggleLayer(this.layers.rainpath, true)
    this.isLayerWeatherStationsDisplayed === true && this.toggleLayer(this.layers.weatherstation, true, true)
    this.isLayerWindArrowsDisplayed === true && this.toggleLayer(this.layers.windarrows, true)
    this.savePrefs = true

    /**
     * Attach a ResizeObserver to react on map resizing
     * related to #416
     */
    this.mapResizer = new ResizeObserver(() => {
      this.resizeTimeout = setTimeout(() => {
        if (this && this.map) {
          this.map.invalidateSize()
        }
      }, 100)
    })
    this.mapResizer.observe(this.$el)
    this.addAirQualityLayer()
  },
  beforeDestroy () {
    this.mapResizer.unobserve(this.$el)
    this.resizeTimeout && clearTimeout(this.resizeTimeout)
    this.savePrefs = false
    this.map.remove()
  },
  methods: {

    addOSMLayer () {
      if (this.layers.osm) this.layers.osm.removeFrom(this.map)
      this.layers.osm = getOSMLayer(this.event.code, this.mapBackground)
      this.drawLayerTarget()
      this.adaptLayerTarget()
      if (this.isLayerOsmDisplayed) {
        this.layers.osm.addTo(this.map)
        this.layers.osm.setOpacity(this.osmOpacity)
      }
    },

    updateColorLayerTarget (opacity) {
      const color = opacity > 0.6 ? '#777' : '#ccc'
      this.layers.targetFull.eachLayer(l => l.setStyle({
        color
      }))
      this.layers.targetMid.eachLayer(l => l.setStyle({
        color
      }))
      this.layers.targetSmall.eachLayer(l => l.setStyle({
        color
      }))
    },

    adaptLayerTarget () {
      if (this.map.getZoom() >= this.zoomFull) {
        this.layers.targetFull.addTo(this.map)
        this.layers.markerFull.addTo(this.map)
        this.layers.targetMid.removeFrom(this.map)
        this.layers.markerMid.removeFrom(this.map)
        this.layers.targetSmall.removeFrom(this.map)
        this.layers.markerSmall.removeFrom(this.map)
      } else if (this.map.getZoom() <= this.zoomSmall) {
        this.layers.targetFull.removeFrom(this.map)
        this.layers.markerFull.removeFrom(this.map)
        this.layers.targetMid.removeFrom(this.map)
        this.layers.markerMid.removeFrom(this.map)
        this.layers.targetSmall.addTo(this.map)
        this.layers.markerSmall.addTo(this.map)
      } else {
        this.layers.targetFull.removeFrom(this.map)
        this.layers.markerFull.removeFrom(this.map)
        this.layers.targetMid.addTo(this.map)
        this.layers.markerMid.addTo(this.map)
        this.layers.targetSmall.removeFrom(this.map)
        this.layers.markerSmall.removeFrom(this.map)
      }
    },

    adaptLayerRainpath () {
      if (!this.isLayerRainpathDisplayed) {
        this.toggleLayer(this.layers.rainpath, false)
        this.toggleLayer(this.layers.markerRainpathLayerFull, false)
        this.toggleLayer(this.layers.markerRainpathLayerMid, false)
        this.toggleLayer(this.layers.markerRainpathLayerSmall, false)
      } else {
        this.toggleLayer(this.layers.rainpath, true)
        if (this.map.getZoom() >= this.zoomFull) {
          this.toggleLayer(this.layers.markerRainpathLayerFull, true)
          this.toggleLayer(this.layers.markerRainpathLayerMid, false)
          this.toggleLayer(this.layers.markerRainpathLayerSmall, false)
        } else if (this.map.getZoom() >= this.zoomSmall) {
          this.toggleLayer(this.layers.markerRainpathLayerFull, false)
          this.toggleLayer(this.layers.markerRainpathLayerMid, true)
          this.toggleLayer(this.layers.markerRainpathLayerSmall, false)
        } else {
          this.toggleLayer(this.layers.markerRainpathLayerFull, false)
          this.toggleLayer(this.layers.markerRainpathLayerMid, false)
          this.toggleLayer(this.layers.markerRainpathLayerSmall, true)
        }
      }
    },

    drawLayerTarget () {
      this.layers.targetFull && this.layers.targetFull.removeFrom(this.map)
      this.layers.targetMid && this.layers.targetMid.removeFrom(this.map)
      this.layers.targetSmall && this.layers.targetSmall.removeFrom(this.map)
      this.layers.markerFull && this.layers.markerFull.removeFrom(this.map)
      this.layers.markerMid && this.layers.markerMid.removeFrom(this.map)
      this.layers.markerSmall && this.layers.markerSmall.removeFrom(this.map)

      const radius = MAP_TARGET_CIRCLE_INTERVAL
      const numberOfCircles = MAP_TARGET_CIRCLE_ITERATION

      const {
        targetLayerFull,
        targetLayerMid,
        targetLayerSmall,
        markerLayerFull,
        markerLayerMid,
        markerLayerSmall
      } = getTargetLayer(this.center, radius, numberOfCircles, this.mapDark)

      this.layers.targetFull = targetLayerFull
      this.layers.targetMid = targetLayerMid
      this.layers.targetSmall = targetLayerSmall
      this.layers.markerFull = markerLayerFull
      this.layers.markerMid = markerLayerMid
      this.layers.markerSmall = markerLayerSmall
    },

    drawRainpath (speed, direction) {
      // distance traveled by raincells in 5 minutes
      const distanceTraveledByRaincells = speed * 1000 / 12
      this.layers.rainpath.clearLayers()
      this.layers.markerRainpathLayerFull.clearLayers()
      this.layers.markerRainpathLayerMid.clearLayers()
      this.layers.markerRainpathLayerSmall.clearLayers()
      if (!isNaN(distanceTraveledByRaincells)) {
        const {
          rainpathLayer,
          markerRainpathLayerFull,
          markerRainpathLayerMid,
          markerRainpathLayerSmall
        } = getRainpath(
          this.center,
          direction,
          distanceTraveledByRaincells,
          'rainpath',
          this.map,
          12
        )
        this.layers.rainpath.addLayer(rainpathLayer)
        this.layers.markerRainpathLayerFull.addLayer(markerRainpathLayerFull)
        this.layers.markerRainpathLayerMid.addLayer(markerRainpathLayerMid)
        this.layers.markerRainpathLayerSmall.addLayer(markerRainpathLayerSmall)
      }
    },

    drawLayerWeatherData () {
      if (!this.stationsData) return
      // first remove all elements from the weatherstation & windarrows layers
      this.layers.weatherstation.clearLayers()
      this.layers.windarrows.clearLayers()

      // then add every station to the weatherstation layer
      this.event.stations.forEach((currentStation, index) => {
        const currentData: WeatherStationData = this.stationsData[index]
        if (currentData) {
          const {
            isRainIntensityOverLimit,
            windDirectionDegree,
            windSpeed,
            gusts,
            stationId
          } = currentData

          // if no direction is set,
          // we don't create arrows of mean + gust
          if (windDirectionDegree === null) return

          const { geometry: { coordinates }, properties } = currentStation

          // creating wind arrows
          const windOrientation = windDirectionDegree - 180 // winDirection is the wind origin !
          const meanOptions = { ...this.arrowOptions, color: this.colors[index].mean, zIndex: 2 }
          const [meanArrow, meanHead] = getWindArrow(coordinates, windOrientation, windSpeed as number, meanOptions)
          meanArrow.bindTooltip(
            `Ground station: ${stationId}<br/>Wind: ${windSpeed}kph<br/>Wind gusts: ${gusts}kph`,
            { sticky: true }
          )
          meanHead.bindTooltip(
            `Ground station: ${stationId}<br/>Wind: ${windSpeed}kph<br/>Wind gusts: ${gusts}kph`,
            { sticky: true }
          )

          const gustOptions = { ...this.arrowOptions, color: this.colors[index].gust, zIndex: 1 }
          const [gustArrow, gustHead] = getWindArrow(coordinates, windOrientation, gusts as number, gustOptions)
          gustArrow.bindTooltip(
            `Ground station: ${stationId}<br/>Wind: ${windSpeed}kph<br/>Wind gusts: ${gusts}kph`,
            { sticky: true }
          )
          gustHead.bindTooltip(
            `Ground station: ${stationId}<br/>Wind: ${windSpeed}kph<br/>Wind gusts: ${gusts}kph`,
            { sticky: true }
          )

          this.layers.windarrows.addLayer(gustArrow)
          this.layers.windarrows.addLayer(gustHead)
          this.layers.windarrows.addLayer(meanArrow)
          this.layers.windarrows.addLayer(meanHead)

          const [y, x] = coordinates
          const prefKey = `map_${this.id}_station_${this.event.id}_${currentStation.properties.reference}`
          const latLng = L.latLng(x, y)
          const icon = L.divIcon({
            html: '<button>' + properties.reference + '</button>',
            className: 'ButtonBadge ' + (isRainIntensityOverLimit === true ? 'OverLimit' : '')
          })
          const marker = L.marker(latLng, { icon: icon })
          const popup = getLeafletPopup(currentData, currentStation, this.displayWheatherStationDialogButton)
          marker.bindPopup(popup)
          marker.on('click', () => {
            preferences.updateStations(prefKey, marker.isPopupOpen())
          })
          marker.on('popupclose', () => {
            if (this.savePrefs) { preferences.updateStations(prefKey, false) }
          })
          if (this.displayWheatherStationDialogButton) {
            marker.on('popupopen', () => {
              L.DomEvent.on(
                L.DomUtil.get(`open-wheather-station-dialog-${stationId}`),
                'click',
                () => {
                  this.$emit('open-wheather-station-dialog', stationId)
                }
              )
            })
          }
          this.layers.weatherstation.addLayer(marker)
          if (preferences.stations[prefKey] === true) {
            marker.openPopup()
          }
        }
      })
    },

    addSourceAndLayer (timestamp) {
      // we create a new layer only if it doesn't exist yet
      if (!this.layers.time[timestamp]) {
        this.layers.time[timestamp] = getRadarLayer(
          timestamp,
          this.bbox,
          this.imageResolution,
          this.radarOpacity
        )
      }
      const currentLayer = this.layers.time[timestamp]
      if (currentLayer) {
        if (!this.map.hasLayer(currentLayer)) {
          currentLayer.addTo(this.map)
        }
        currentLayer.hide()
      }
    },

    addAirQualityLayer (timestamp: string) {
      if (!this.airParifLayerAllowed) return
      if (!this.layers.airquality[timestamp]) {
        this.layers.airquality[timestamp] = getAirParifLayer(
          timestamp,
          'indice',
          this.airParifOpacity
        )
      }
      const currentLayer = this.layers.airquality[timestamp]
      if (currentLayer) {
        if (!this.map.hasLayer(currentLayer)) {
          currentLayer.addTo(this.map)
        }
        currentLayer.hide()
      }
    },

    addDataLayer (times: string[], timesIndex: number) {
      switch (this.dataLayer) {
        case 'radar':
          this.removeActiveAirParifLayer()
          this.displayNewTimeRadar(times, timesIndex)
          break

        case 'airquality':
          this.removeActiveLayer()
          this.displayNewTimeAirParif(times, timesIndex)
          break
      }
    },

    removeActiveLayer () {
      if (this.activeLayer) {
        this.activeLayer.removeFrom(this.map)
      }
    },
    removeActiveAirParifLayer () {
      if (!this.airParifLayerAllowed) return
      if (this.activeAirParifLayer) {
        this.activeAirParifLayer.removeFrom(this.map)
      }
    },

    showTimeLayer (timestamp) {
      const currentLayer = this.layers.time[timestamp]
      if (currentLayer) {
        if (!this.map.hasLayer(currentLayer)) {
          currentLayer.addTo(this.map).show()
        } else {
          currentLayer.show()
        }
        this.activeLayer = currentLayer
      }
    },

    showAirParifLayer (timestamp) {
      if (!this.airParifLayerAllowed) return
      const timestampFixed = timestamp.substr(0, 10) + '0000'

      const currentLayer = this.layers.airquality[timestampFixed]
      if (currentLayer) {
        if (!this.map.hasLayer(currentLayer)) {
          currentLayer.addTo(this.map).show()
        } else {
          currentLayer.show()
        }
        this.activeAirParifLayer = currentLayer
      }
    },

    toggleLayer (layer, shouldDisplayLayer: boolean = null, shouldDisplayPopup: boolean) {
      if (layer) {
        if (shouldDisplayLayer === false || (shouldDisplayLayer !== true && this.map.hasLayer(layer))) {
          layer.removeFrom(this.map)
        } else {
          layer.addTo(this.map)
          if (shouldDisplayPopup) {
            layer.getLayers().forEach((currentLayer, index) => {
              const prefKey = `map_${this.id}_station_${this.event.id}_${this.stationsData[index].stationId}`
              if (preferences.stations[prefKey] === true) { currentLayer.openPopup() }
            })
          }
        }
      }
    },

    displayNewTimeRadar (times: string[], index: number) {
      // we create if needed the `numberOfForwardLayers` sources/layers
      // following the current time (included) to display
      for (let i = 0; i < this.numberOfForwardLayers; i++) {
        const timestampUTC = times[(index + i) % times.length]
        this.addSourceAndLayer(timestampUTC)
      }
      // show the new layer
      this.showTimeLayer(times[index])
    },

    /**
     * Display a layer for AirParif if needed
     */
    displayNewTimeAirParif (times, index) {
      if (!this.airParifLayerAllowed) return

      /**
       * Compute a starting time with HH:mm to 00:00
       */
      const startTime = times[index].substr(0, 10) + '0000'
      if (this.currentAirParifTime === startTime) {
        // we show it if it's the same
        this.showAirParifLayer(startTime)
        return
      }

      this.removeActiveAirParifLayer()

      this.currentAirParifTime = null
      // we create if needed the `numberOfForwardLayers` sources/layers
      // following the current time (included) to display
      for (let i = 0; i < 3; i++) {
        // we add layers every 60 minutes
        const timestampUTC = times[(index + i * 60) % times.length]
        // reset hours & minutes to 0
        this.addAirQualityLayer(timestampUTC.substr(0, 10) + '0000')
      }
      // show the new layer
      this.showAirParifLayer(startTime)
      this.currentAirParifTime = startTime
    },
    setRadarOpacity (value: number): void {
      Object.values(this.layers.time).forEach(l => {
        // it's possible that a layer is null, we check before changing opacity
        l && (l._opacity = value)
      })
    },
    setAirParifOpacity (value: number): void {
      if (!this.airParifLayerAllowed) return
      Object.values(this.layers.airquality).forEach(l => {
        // it's possible that a layer is null, we check before changing opacity
        l && (l._opacity = value)
      })
    },
    zoomIn (): void {
      this.map && this.map.zoomIn()
    },
    zoomOut (): void {
      this.map && this.map.zoomOut()
    }
  },
  watch: {
    mapBackground () {
      this.addOSMLayer()
    },
    timesIndex (newValue) {
      if (newValue === null || !this.times) return
      // first we remove the active layer
      this.removeActiveLayer()

      this.addDataLayer(this.times, newValue)
    },
    times (newTimes) {
      if (!newTimes || this.timesIndex === null) return

      // first we remove the active layer
      this.removeActiveLayer()
      this.addDataLayer(newTimes, this.timesIndex)

      this.setRadarOpacity(this.radarOpacity)
    },
    dataLayer () {
      this.addDataLayer(this.times, this.timesIndex)
    },
    rain (newValue) {
      if (newValue) {
        this.drawRainpath(newValue.speed, newValue.direction)
      }
      if (this.isLayerRainpathDisplayed && this.map &&
          !this.map.hasLayer(this.layers.rainpath)) {
        this.toggleLayer(this.layers.rainpath, true)
      }
    },
    stationsData (newValue) {
      this.savePrefs = false
      if (newValue) {
        this.drawLayerWeatherData()
      }
      if (this.isLayerWeatherStationsDisplayed &&
        !this.map.hasLayer(this.layers.weatherstation)) {
        this.toggleLayer(this.layers.weatherstation, true, true)
      }
      if (this.isLayerWindArrowsDisplayed &&
        !this.map.hasLayer(this.layers.windarrows)) {
        this.toggleLayer(this.layers.windarrows, true)
      }
      this.savePrefs = true
    },
    isLayerOsmDisplayed (newValue) {
      this.toggleLayer(this.layers.osm, newValue)
    },
    isLayerRainpathDisplayed () {
      this.adaptLayerRainpath()
    },
    isLayerWeatherStationsDisplayed (newValue) {
      this.savePrefs = newValue
      this.toggleLayer(this.layers.weatherstation, newValue, true)
    },
    isLayerWindArrowsDisplayed (newValue) {
      this.toggleLayer(this.layers.windarrows, newValue)
    },
    centerMap () {
      setTimeout(() => {
        this.map.invalidateSize(true)
      }, 300)
    },
    osmOpacity (newValue) {
      this.layers.osm.setOpacity(newValue)
      this.updateColorLayerTarget(newValue)
    },
    radarOpacity (newValue) {
      this.setRadarOpacity(newValue)
      this.showTimeLayer(this.times[this.timesIndex])
    },
    airParifOpacity (newValue) {
      this.setAirParifOpacity(newValue)
      this.showAirParifLayer(this.times[this.timesIndex])
    },
    centerPreference (newValue, oldValue) {
      const actualCenter = this.map.getCenter()
      /**
       * Check if the new center is the same than the actual,
       * if yes, we don't do anything
       */
      if (
        newValue &&
        newValue.lat === actualCenter.lat &&
        newValue.lng === actualCenter.lng
      ) return
      /**
       * If the newValue is null and before it was not the case,
       * we center the map
       */
      if (!newValue && newValue !== oldValue) {
        this.map.panTo(this.center)
      }
    }
  }
})
