// @ts-nocheck
import L from 'leaflet'
import GeometryUtil from 'leaflet-geometryutil'

import { point } from '@turf/helpers'
import destination from '@turf/destination'
import flip from '@turf/flip'

import { getArrowheadWinds } from '@/components/map/commons/helper'
import { WeatherStationData } from '@/store/weatherstation/definitions'
import { WeatherEventStation } from '@/store/event/definitions'
import { AirParifPollutantKey } from '@/store/airparif/definitions'
import { stringifyDate, convertDate } from '@/helpers/dates'

export function getOSMLayer (eventCode: string, mapBackground: 'light' | 'dark' | 'shadedrelief' | 'satellite' = 'light') {
  if (mapBackground === 'satellite') {
    return L.tileLayer(`${TILES_OSM_URL}${eventCode}/satellite/{z}/{x}/{y}.jpg`, {
      attribution:
        '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="https://openstreetmap.org">OpenStreetMap</a>, <a href="httsp://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, <a href="https://www.meteofrance.com">Meteo France</a>',
      maxNativeZoom: 15
    })
  }
  return L.tileLayer(`${TILES_OSM_URL}${eventCode}/${mapBackground}/{z}/{x}/{y}.png`, {
    attribution:
      'Map data &copy; <a href="https://openstreetmap.org">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, <a href="https://www.meteofrance.com">Meteo France</a>',
    maxNativeZoom: 15
  })
}

function rotatePoint (map: L.Map, latlngPoint: L.LatLng, angleDeg: number, latlngCenter: L.LatLng): L.LatLng {
  let maxzoom = map.getMaxZoom()
  if (maxzoom === Infinity) maxzoom = map.getZoom()
  const angleRad = angleDeg * Math.PI / 180
  const pPoint = map.project(latlngPoint, maxzoom)
  const pCenter = map.project(latlngCenter, maxzoom)
  const x2 =
      Math.cos(angleRad) * (pPoint.x - pCenter.x) -
      Math.sin(angleRad) * (pPoint.y - pCenter.y) +
      pCenter.x
  const y2 =
      Math.sin(angleRad) * (pPoint.x - pCenter.x) +
      Math.cos(angleRad) * (pPoint.y - pCenter.y) +
      pCenter.y
  return map.unproject(new L.Point(x2, y2), maxzoom)
}

export function getRadarLayer (
  currentTimeString,
  bbox,
  suffixURL,
  opacity = 1,
  minuteForecastedImage = 0
) {
  if (!bbox) return null
  const lowerLeft = [bbox.coordinates[0][0][1], bbox.coordinates[0][0][0]]
  const upperRight = [bbox.coordinates[0][2][1], bbox.coordinates[0][2][0]]
  const bounds = L.latLngBounds(lowerLeft, upperRight)
  let minutes = ''
  if (minuteForecastedImage > 0) {
    minutes += '/+'
    if (minuteForecastedImage < 10) minutes += '0'
    minutes += minuteForecastedImage
    minutes += '00'
  }

  const currentLayer = L.imageOverlay(
    TILES_RADAR_URL + currentTimeString + minutes + suffixURL /* '/full_image.png' */,
    bounds
  )
  currentLayer._opacity = opacity
  currentLayer.hide = function () {
    this.setOpacity(0)
    if (this.getElement()) {
      this.getElement().classList.add('display-none')
    }
  }.bind(currentLayer)
  currentLayer.show = function () {
    if (this.getElement()) {
      this.getElement().classList.remove('display-none')
    }
    this.setOpacity(this._opacity)
  }.bind(currentLayer)
  currentLayer.hide()
  return currentLayer
}

export function getAirParifLayer (
  currentTimeString: string,
  state: AirParifPollutantKey = 'indice',
  opacity = 1
) {
  const authkey = '7d2a2d15-2887-2d22-3754-bb93ac846ad6'
  // const time = '2023-04-18T12:00:00.000Z'
  const wmsOptions = {
    service: 'WMS',
    version: '1.3',
    layers: 'apisHorAir:' + state + '_api',
    tiled: true,
    transparent: true,
    format: 'image/png8',
    styles: state,
    authkey,
    attribution: 'Air Parif © 2023',
    zIndex: 10
  }
  if (currentTimeString) {
    wmsOptions.time = stringifyDate(convertDate(currentTimeString), "yyyy-MM-dd'T'T':00.000Z'")
  }
  const currentLayer = L.tileLayer.wms('https://magellan.airparif.asso.fr/geoserver/apisHorAir/wms?', wmsOptions)
  currentLayer._opacity = opacity
  currentLayer.hide = function () {
    this.setOpacity(0)
  }.bind(currentLayer)
  currentLayer.show = function () {
    this.setOpacity(this._opacity)
  }.bind(currentLayer)
  currentLayer.hide()
  return currentLayer
}

/**
 * Return a layer of a target,
 * based on a center point, a radius, and a number of circles
 *
 * @export
 * @param {LatLng} center
 * @param {number} radius
 * @param {number} numberOfCircles
 */
export function getTargetLayer (center, radius, numberOfCircles, darkMode = false) {
  const color = darkMode ? '#ddd' : '#777'
  const targetLayerFull = L.featureGroup()
  const targetLayerMid = L.featureGroup()
  const targetLayerSmall = L.featureGroup()
  const markerLayerFull = L.featureGroup()
  const markerLayerMid = L.featureGroup()
  const markerLayerSmall = L.featureGroup()

  // full circles
  for (let i = 1; i <= numberOfCircles; i++) {
    const currentCircleFull = L.circle(center, {
      radius: radius * i,
      color: color,
      weight: 2,
      interactive: false,
      fill: false,
      pane: 'target'
    })
    const icon = L.divIcon({
      html: radius * i / 1000 + ' kms',
      className: 'IconDistance'
    })
    const currentMarkerFull = L.marker(
      GeometryUtil.destination(L.latLng(center), 270, radius * i),
      {
        icon: icon,
        title: radius * i / 1000 + ' kms',
        alt: radius * i / 1000 + ' kms',
        interactive: false
      }
    )
    targetLayerFull.addLayer(currentCircleFull)
    markerLayerFull.addLayer(currentMarkerFull)
  }

  // mid circles, one every two radius
  for (let i = 2; i <= numberOfCircles; i = i + 2) {
    const currentCircleMid = L.circle(center, {
      radius: radius * i,
      color: color,
      weight: 2,
      interactive: false,
      fill: false,
      pane: 'target'
    })
    const icon = L.divIcon({
      html: radius * i / 1000 + ' kms',
      className: 'IconDistance'
    })
    const currentMarkerMid = L.marker(GeometryUtil.destination(L.latLng(center), 270, radius * i), {
      icon: icon,
      title: radius * i / 1000 + ' kms',
      alt: radius * i / 1000 + ' kms',
      interactive: false
    })
    targetLayerMid.addLayer(currentCircleMid)
    markerLayerMid.addLayer(currentMarkerMid)
  }

  // small circles, one every 6 radius
  for (let i = 6; i <= numberOfCircles; i = i + 6) {
    const currentCircleSmall = L.circle(center, {
      radius: radius * i,
      color: color,
      weight: 2,
      interactive: false,
      fill: false,
      pane: 'target'
    })
    const icon = L.divIcon({
      html: radius * i / 1000 + ' kms',
      className: 'IconDistance'
    })
    const currentMarkerSmall = L.marker(
      GeometryUtil.destination(L.latLng(center), 270, radius * i),
      {
        icon: icon,
        title: radius * i / 1000 + ' kms',
        alt: radius * i / 1000 + ' kms',
        interactive: false
      }
    )
    targetLayerSmall.addLayer(currentCircleSmall)
    markerLayerSmall.addLayer(currentMarkerSmall)
  }

  // X & Y axes
  targetLayerFull.addLayer(
    L.polyline([[center[0] + 4, center[1]], [center[0] - 4, center[1]]], {
      color: color,
      weight: 2,
      interactive: false,
      pane: 'target'
    })
  )
  targetLayerMid.addLayer(
    L.polyline([[center[0] + 4, center[1]], [center[0] - 4, center[1]]], {
      color: color,
      weight: 2,
      interactive: false,
      pane: 'target'
    })
  )
  targetLayerSmall.addLayer(
    L.polyline([[center[0] + 4, center[1]], [center[0] - 4, center[1]]], {
      color: color,
      weight: 2,
      interactive: false,
      pane: 'target'
    })
  )
  targetLayerFull.addLayer(
    L.polyline([[center[0], center[1] - 4], [center[0], center[1] + 4]], {
      color: color,
      weight: 2,
      interactive: false,
      pane: 'target'
    })
  )
  targetLayerMid.addLayer(
    L.polyline([[center[0], center[1] - 4], [center[0], center[1] + 4]], {
      color: color,
      weight: 2,
      interactive: false,
      pane: 'target'
    })
  )
  targetLayerSmall.addLayer(
    L.polyline([[center[0], center[1] - 4], [center[0], center[1] + 4]], {
      color: color,
      weight: 2,
      interactive: false,
      pane: 'target'
    })
  )

  return {
    targetLayerFull: targetLayerFull,
    targetLayerMid: targetLayerMid,
    targetLayerSmall: targetLayerSmall,
    markerLayerFull: markerLayerFull,
    markerLayerMid: markerLayerMid,
    markerLayerSmall: markerLayerSmall
  }
}

export function drawArrowHead (
  bearing: number,
  latlng: L.LatLng,
  options = {}
): L.Polygon {
  const defaultOptions = {
    fill: true,
    fillOpacity: 1,
    smoothFactor: 1
  }
  const hatOptions = { ...defaultOptions, ...options }
  const [left, right] = getArrowheadWinds([latlng.lng, latlng.lat], bearing, 0.15)

  // coords are return as [lng, lat]
  // and leaflet takes [lat,lng]
  const hatPoints = [
    left.reverse(),
    [latlng.lat, latlng.lng],
    right.reverse()
  ]
  return L.polygon(hatPoints, hatOptions)
}

/**
 * Get the rain path
 * @param {Object} center Center of the map
 * @param {number} direction Direction in degrees, with 0° = North axis
 * @param {number} distance Distance traveled by raincells in a time unit
 * The time unit is defined by the caller of the function
 * @param {Object} pane Pane in which will be added the rainpath
 * @param {Object} map Map that will receive the rainpath, used for projection
 * @param {number} numberOfSteps Number of steps on the rainpath
 */
export function getRainpath (center, direction, distance, pane, map, numberOfSteps) {
  const rainpathLayer = L.featureGroup()
  const markerRainpathLayerFull = L.featureGroup()
  const markerRainpathLayerMid = L.featureGroup()
  const markerRainpathLayerSmall = L.featureGroup()

  const moduloLayerMid = distance < 1500 ? 3 : 2
  const moduloLayerSmall = distance < 1500 ? 6 : 4

  // const color = '#4F05F2'
  const color = '#D63333'

  if (isNaN(distance)) return rainpathLayer

  // // To calculate distance in meter & convert them in LatLng please check the following link
  // // http://stackoverflow.com/questions/7477003/calculating-new-longtitude-latitude-from-old-n-meters
  let previousPoint = { lat: center[0], lng: center[1] }
  const polylineOptions = { pane, color, weight: 4 }
  const segmentArrow = drawArrowHead(direction - 180, L.latLng(...center), polylineOptions)

  rainpathLayer.addLayer(segmentArrow)

  // make the rain path
  for (let i = 1; i <= numberOfSteps; i++) {
    let newPoint = {
      lat: center[0] + distance * i / L.CRS.Earth.R * (180 / Math.PI),
      lng: center[1] + 0 / L.CRS.Earth.R * (180 / Math.PI) / Math.cos(center[0] * Math.PI / 180)
    }

    newPoint = rotatePoint(map, newPoint, direction + 180, {
      lat: center[0],
      lng: center[1]
    })

    const rotationBase = (i === numberOfSteps || i === numberOfSteps / 2) ? 20 : 10

    const segmentA = rotatePoint(map, newPoint, 0 + rotationBase, previousPoint)
    const segmentB = rotatePoint(map, newPoint, 360 - rotationBase, previousPoint)

    const rainpath = L.polyline([newPoint, previousPoint], polylineOptions)
    rainpathLayer.addLayer(rainpath)

    const segmentAB = L.polyline([segmentA, segmentB], polylineOptions)
    rainpathLayer.addLayer(segmentAB)
    const label = Math.trunc(60 * i / numberOfSteps) + '\''
    const icon = L.divIcon({
      html: label,
      className: 'RainpathDistance'
    })
    const currentMarker = L.marker(
      L.latLng(newPoint),
      {
        icon,
        title: label,
        alt: label,
        interactive: false
      }
    )
    markerRainpathLayerFull.addLayer(currentMarker)

    if (i % moduloLayerMid === 0) {
      const currentMarkerMid = L.marker(
        L.latLng(newPoint),
        {
          icon,
          title: label,
          alt: label,
          interactive: false
        }
      )
      markerRainpathLayerMid.addLayer(currentMarkerMid)
    }
    if (i % moduloLayerSmall === 0) {
      const currentMarkerSmall = L.marker(
        L.latLng(newPoint),
        {
          icon,
          title: label,
          alt: label,
          interactive: false
        }
      )
      markerRainpathLayerSmall.addLayer(currentMarkerSmall)
    }

    previousPoint = newPoint
  }

  rainpathLayer.setStyle({ interactive: false })

  return {
    rainpathLayer,
    markerRainpathLayerFull,
    markerRainpathLayerMid,
    markerRainpathLayerSmall
  }
}

export function getLeafletPopup (
  { stationId, windDirection, airTemperature, rainIntensity }: WeatherStationData,
  { geometry, properties }: WeatherEventStation,
  displayWheatherStationDialogButton: boolean
) {
  const indexOfSpaceInPosition = properties.position.indexOf(' ')
  const firstPosition = indexOfSpaceInPosition > -1
    ? properties.position.substring(0, indexOfSpaceInPosition)
    : properties.position
  const popupOptions: any = { className: firstPosition }
  switch (firstPosition) {
    case 'left':
      popupOptions.offset = L.point(
        -80,
        displayWheatherStationDialogButton ? 80 : 50
      )
      break
    case 'right':
      popupOptions.offset = L.point(
        90,
        displayWheatherStationDialogButton ? 80 : 50
      )
      break
    case 'top':
      popupOptions.offset = L.point(5, 0)
      break
    case 'bottom':
      popupOptions.offset = L.point(5, 100)
      break
  }

  let content = `
    <div>
      <span class="icon-ui-rain"></span>
      <span class="rain">${rainIntensity}</span>
      <span class="unit">mm/h</span>
    </div>
    <div>
      <span class="icon-wind-${windDirection}"></span>
      <span class="temperature">${airTemperature}</span>
      <span class="unit">°C</span>
    </div>
  `

  if (displayWheatherStationDialogButton) {
    content += `
      <div class="flex justify-center m-2">
        <button id="open-wheather-station-dialog-${stationId}" class="Button button-ui Button--contained">
        Show charts
        </button>
      </div>
    `
  }

  const popup = L.popup(popupOptions)
    .setLatLng(L.latLng(geometry.coordinates[1], geometry.coordinates[0]))
    .setContent(content)

  return popup
}
/**
 * Get a wind arrow polyline and arrow head marker based on wind speed and direction.
 * @param center - The center point of the wind arrow.
 * @param direction - The direction (in degrees) the wind is blowing from.
 * @param windSpeed - The speed (in kilometers per hour) of the wind.
 * @param options - Options to pass to the polyline and arrow head marker.
 * @returns A tuple of a wind arrow polyline and arrow head marker.
 */
export function getWindArrow (
  center: [number, number],
  direction: number,
  windSpeed: number,
  options: Record<string, any>
): L.Polyline {
  // the middle of wind arrow is the center
  // so we divide the distance by 12 (equal to 5mn of distance, windSpeed is in kph)
  // then by 2
  const distance = windSpeed / 12 / 2

  const { geometry: { coordinates: coordsHead } } = destination(point(center), distance, direction)
  const [x, y] = center
  const arrowCoords = [L.latLng(y, x)]
  const latLng = L.latLng(...coordsHead.reverse())

  const { geometry: { coordinates: coordsTail } } = flip(destination(point(center), distance, direction - 180))
  const arrowTail = L.latLng(...coordsTail)

  const arrow = L.polyline([arrowCoords, [arrowTail, latLng]], options)

  // direction props is the wind origin
  const head = drawArrowHead(direction - 180, latLng, options)
  return [arrow, head]
}
